.constr {
    grid-area: constr;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.constrContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    width: 100%;
    max-width: 580px;
}

.constrContainer::-webkit-scrollbar {
    width: 6px;
}

.constrContainer::-webkit-scrollbar-track-piece {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #2F2F35;
}

.constrContainer::-webkit-scrollbar-thumb {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #8485AA;
}

.toppingsWindow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: calc(100vh - 498px);
    max-height: calc(88px*5);
    overflow-y: auto;
    justify-content: flex-end;
    width: 100%;
}

.toppingsWindow::-webkit-scrollbar {
    width: 6px;
}

.toppingsWindow::-webkit-scrollbar-track-piece {
    background-color: #2F2F35;
}

.toppingsWindow::-webkit-scrollbar-thumb {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #8485AA;
}

.insideIngrediend {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    align-items: center;
    width: 100%;
}

.inside {
    margin-right: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.insideIngrediend:first-of-type {
    margin-top: 0px;
    display: flex;
    width: 100%;
}

.outside {
    margin-right: 16px;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    width: 100%;
}

.icon:hover {
    cursor: move;
}

.total {
    margin-right: 48px;
    margin-top: 28px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.totalPrice {
    margin-right: 40px;
}

.isHover {
    background-color: #1C1C21;
    border: #fff 1px solid;
}

.chooseIngredient {
    display: flex;
    align-self: center;
    justify-content: center;
  }
  