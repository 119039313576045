
.feedOrdersContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 180px);
    overflow-y: auto;
}

.feedOrdersContainer::-webkit-scrollbar {
    width: 6px;   
}

.feedOrdersContainer::-webkit-scrollbar-track-piece {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #2F2F35;
}

.feedOrdersContainer::-webkit-scrollbar-thumb {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #8485AA;
}


.h2 {
    display: flex;
    width: 100%;
}