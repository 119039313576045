.modalOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    background-color: black;
    opacity: 60%;
}

.modalOverlayHidden {
    visibility: hidden;
}

