
.navButton {
  text-decoration: none;
  color: #8585AD;
  border: none;
  background: none;
}

.navButton:hover {
  cursor: pointer;
  opacity: 0.7;
}

.navButtonIsActive {
  text-decoration: none;
  color: #F2F2F3;
}

.navButtonIsActive:hover {
  cursor: default;
  opacity: 1;
}

.form {
  margin-top: 160px;
  margin-left: 165px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;

}

.input {
  margin-bottom: 24px;
}

.button {
  margin-bottom: 80px;
}

.textButton {
  width: 173px;
  height: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4C4CFF;
  text-decoration: none;
  margin-bottom: 16px;
}

.actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.navButtonDisabled:hover {
  cursor: default;
  opacity: 0.3;
}
.navButtonDisabled {
  opacity: 0.3  ;
}
