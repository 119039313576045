.orderDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    height: 600px;
    align-items: center;
    align-self: center;
}

.image {
    max-width: 480px;
}

.orderNumber {
    margin-top: 120px;
    margin-bottom: 32px;
}

.orderId {
    margin-bottom: 60px;
}

.checkMarkIcon {
    margin-bottom: 60px;
}

.startCooking {
    margin-bottom: 8px;
}

.wait {
    margin-bottom: 120px;
}


