.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    overflow: auto; 
    z-index: 300;
}

.modalHidden {
    visibility: hidden;
    content-visibility: hidden;
}

.modalContent {
    margin: auto;
    background-color: #1C1C21;
    max-width: 720px;
    width: calc(100% - 80px);
    position: relative;
    border-radius: 40px;
}

.closeIcon {
    display: flex;
    position: absolute;
    top: 50px;
    right: 40px;
    transition: 0.2s;
}

.closeIcon:hover {
    opacity: 80%;
    cursor: pointer;
    transition: 0.2s;
} 