.container {
  display: flex;
  align-content: center;
  
  height: 100%;
  width: 100%;
}

.nav {
  margin-top: 160px;
  max-width: 330px;
  padding-right: 60px; 
}

.link {
  margin-bottom: 20px;
}


.links {
  padding: 0;
  list-style: none;
  color: #4C4CFF;
  text-decoration: none;
  margin-bottom: 60px;
}


.navButton {
  text-decoration: none;
  color: #8585AD;
  border: none;
  background: none;
}

.navButton:hover {
  cursor: pointer;
  opacity: 0.7;
}

.navButtonIsActive {
  text-decoration: none;
  color: #F2F2F3;
}

.navButtonIsActive:hover {
  cursor: default;
  opacity: 1;
}

.h1 {
  margin-bottom: 24px;
  text-align: center;
}

.feedOrders {
  margin-top: 20px;
  max-width: 800px;
  width: 100%;
  height: 100;
}