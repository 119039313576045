.header {
    display: flex;
    flex-direction:row;
    justify-content: center;
    min-height: 80px;
    width: 100%;
    background-color: #1C1C21;
    
}

.nav {
    max-width: 1280px;
    width: calc(100% - 20px*2);
    display: grid;
    grid-template-areas: "links logo profile";
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.links {
    display: flex;
    flex-direction: row;
    list-style: none;
    grid-area: links;
    margin: 0;
    padding: 0;
}

.links__item {
    display: flex;
    align-content: center;
    margin-left: 20px;
}

.links__item:first-child {
    margin-left: 0;
}

.link__text {
    padding-left: 8px !important;
    text-decoration: none;
    justify-self: center;
    color: #8585AD;
}

.link__text_active {
    color: #fff;
}

.logo {
    grid-area: logo;
    justify-self: center;
}

.profile {
    display: flex;
    grid-area: profile;
    align-content: center;
    justify-content: flex-end;
}


