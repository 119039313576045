.selectedOrder {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;

}

.container {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    width: 100%;
    align-self: center;

}

.number {
    margin-top: 60px;
    text-align: left;
}

.title {
    margin-top: 40px;
    margin-left: 0;
    margin-bottom: 12px;
}

.status {
    color: #00CCCC;
    margin-bottom: 60px;
}

.list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 24px 0 0 0;
    min-height: 100px;
    max-height: 240px;
    overflow-y: auto;
}

.list::-webkit-scrollbar {
    width: 6px;   
}

.list::-webkit-scrollbar-track-piece {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #2F2F35;
}

.list::-webkit-scrollbar-thumb {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #8485AA;
}


.item {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.imageAndName {
    display: flex;
    flex-direction: row;
    width: 500px;
}

.ingredientName {
    display: flex;
    margin-left: 16px;
    align-self: center;
}


.imageWithBorder {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    position: relative;
    margin: 2px;
    width: 60px;
    height: 60px;
    border: 2px solid transparent;
    background-image: linear-gradient(black, black), linear-gradient(to right, #801AB2, #4C4CFF);
    background-origin: border-box;
    background-clip: content-box, border-box;
    
}

.imageWithBorder::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: 50%;
    background-image: linear-gradient(45deg, #801AB2, #4C4CFF);
}

.image {
    height: 100%;
}

.qtyAndPrice {
    display: flex;
    align-items: center;  
    padding-right: 16px; 
    min-width: 150px;
    justify-content: flex-end;
}


.timeAndTotalPrice {
    margin-top: 40px;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    align-content: center;
}