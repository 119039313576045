
.feedStatusConteiner {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 180px);
    overflow-y: auto;
    margin-left: 60px;
    max-width: 580px;
    width: 100%;
}

.feedStatusConteiner::-webkit-scrollbar {
    width: 6px;   
}

.feedStatusConteiner::-webkit-scrollbar-track-piece {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #2F2F35;
}

.feedStatusConteiner::-webkit-scrollbar-thumb {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #8485AA;
}


.feedReadyAndInProgress {
    display: flex;
    justify-content: space-between;
}

.feedStatus {
    max-width: 272px;
    width: 100%;
}

.feedList {
    list-style: none;
    padding: 0;
    margin: 0 0 24 0;  
}

.feedListItemReady {
    color: #00CCCC;
    margin-bottom: 8px;
}

.feedListItemInProgress {
    margin-bottom: 8px;
}

.feedH2 {
    display: flex;
    width: 100%;
}

.feedTotal {
    margin-top: 32px;
}

.feedToday {
    margin-top: 32px;
}