.ingredientDetails {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    
}

.image {
    max-width: 480px;
    align-self: center;
}

.title {
    margin-top: 40px;
    margin-left: 40px;
}

.name {
    margin-top: 16px;
    margin-bottom: 32px;
    align-self: center;
}

.nutritionList {
    list-style: none;
    margin: 0 0 60px 0px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-self: center;
    text-align: center;
}

.nutrition {
    margin-left: 20px;
    margin-right: 20px;
}