.container {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.form {
  margin-top: 160px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.h1 {
  margin-bottom: 24px;
  text-align: center;
}

.input {
  margin-bottom: 24px;
}

.button {
  margin-bottom: 80px;
}

.textButton {
  width: 173px;
  height: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4C4CFF;
  text-decoration: none;
  margin-bottom: 16px;
}