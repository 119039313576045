

.h1 {
  margin-top: 40px !important;
}

.feed {
  margin-top: 20px;
  display: grid;
  grid-template-areas: "ingredients constr";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.feedOrder {
  
}