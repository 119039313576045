.ingredients {
    grid-area: ingredients;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    z-index: 100;
}

.tabs {
    display: flex;
    flex-direction: row;
}

.ingredientsContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 235px);
    overflow-y: auto;

}

.ingredientsContainer::-webkit-scrollbar {
    width: 6px;   
}

.ingredientsContainer::-webkit-scrollbar-track-piece {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #2F2F35;
}

.ingredientsContainer::-webkit-scrollbar-thumb {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #8485AA;
}

.ingredientList {
    list-style: none;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding-top: 20px;
}

.h2 {
    display: flex;
    width: 100%;
}