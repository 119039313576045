.feedOrderCard {
    background-color: #1C1C21;
    margin-bottom: 8px;
    margin-top: 8px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    z-index: 0;
    margin-right: 8px;
    transition-duration: 0.2s;

}

.feedOrderCard:hover {
    cursor: pointer;
    opacity: 0.8;

}

.imagesContainer {
    list-style: none;
    padding: 0;
    margin-left: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.imageWithBorder {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    position: relative;
    background: #000;
    border: #4C4CFF 2px solid;
    width: 60px;
    height: 60px;
    max-width: 796px;

    border: 2px solid transparent;
    background-image: linear-gradient(black, black), linear-gradient(to right, #801AB2, #4C4CFF);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.imageWithBorderLastOneConteiner {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    opacity: 0.7;
}

.imageWithBorderLastOne::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    margin: -2px;
    border-radius: inherit;
}

.ingredientsCount {
    position: absolute;
    text-align: center;
    align-self: center;

}


.image {
    height: 100%;

}

.imageLastOne {
    opacity: 0.6;
}

.h2 {
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;

}

.numberAndTime {
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    justify-content: space-between;
}

.priceContainer {
    margin-right: 24px;
    align-content: center;
}

.imagesAndPriceContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.price {
    margin-right: 8px;
}

.icon {
    height: 24px;
    width: 24px;
    height: 100%;
    width: 100%;
    vertical-align: top;
}