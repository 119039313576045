.app {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }
  
  .main {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    width: calc(100% - 20px*2);
    align-self: center;
  }
  
  .constr {
    grid-area: constr;
    height: 100%;
  }